export class Video {
    date!: string
    playlist!: string
    viewCount!: number
    title!: string
    thumbnail!: string
    webpageUrl!: string
    duration!: number
    playlistIndex!: number

    constructor(videoMetadata?: VideoMetadata) {
        this.date = videoMetadata?.d ?? ''
        this.playlist = videoMetadata?.p ?? ''
        this.viewCount = videoMetadata?.v ?? 0
        this.title = videoMetadata?.t ?? ''
        this.thumbnail = videoMetadata?.i ?? ''
        this.webpageUrl = videoMetadata?.u ?? ''
        this.duration = videoMetadata?.l ?? 0
        this.playlistIndex = videoMetadata?.z ?? 0
    }
}

export interface VideosByPlaylist {
    [playlistKey: string]: Playlist
}

export interface Playlist {
    include: boolean
    videos: Video[]
}

export interface VideoMetadata {
    // date
    d?: string
    // playlist
    p?: string
    // viewCount
    v?: number
    // title
    t?: string
    // thumbnail
    i?: string
    // webpageUrl
    u?: string
    // duration
    l?: number
    // playlistIndex
    z?: number
}
