import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { VideosProvider } from '../src/contexts/VideosContext'
import './styles/App.css'
import LandingPage from './pages/LandingPage'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-180784745-1')

const App = () => {
    ReactGA.pageview(window.location.pathname)

    return (
        <VideosProvider>
            <Router>
                <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        </VideosProvider>
    )
}

export default App
