import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import allVideos from '../assets/video_metadata.json'
import { Video, VideoMetadata } from '../interfaces/SharedInterfaces'

interface IDefaultVideosContextValue {
    videoQueue: Video[]
    setVideoQueue: Dispatch<SetStateAction<Video[]>>
    videoIndex: number
    updateVideoIndex: any
    secondsElapsedOnCurrentVideo: number
    setSecondsElapsedOnCurrentVideo: Dispatch<SetStateAction<number>>
    currentVideo: Video
}

const defaultValue: IDefaultVideosContextValue = {
    videoQueue: [],
    setVideoQueue: () => {},
    videoIndex: 0,
    updateVideoIndex: () => {},
    secondsElapsedOnCurrentVideo: 0,
    setSecondsElapsedOnCurrentVideo: () => {},
    currentVideo: new Video(),
}

const VideosContext = React.createContext(defaultValue)

interface IGenericProps {
    children: React.ReactNode
}

const VideosProvider = (props: IGenericProps) => {
    const [videoQueue, setVideoQueue] = useState(generateVideoQueue())
    const [videoIndex, setVideoIndex] = useState(0)
    const [currentVideo, setCurrentVideo] = useState<Video>(new Video())
    const [secondsElapsedOnCurrentVideo, setSecondsElapsedOnCurrentVideo] = useState<number>(0)

    const updateVideoIndex = (newVideoIndex: number) => {
        if (newVideoIndex >= videoQueue.length - 1) {
            setVideoQueue(generateVideoQueue())
            setVideoIndex(0)
        } else {
            setVideoIndex(newVideoIndex)
            setSecondsElapsedOnCurrentVideo(0)
        }
    }

    useEffect(() => {
        setCurrentVideo(videoQueue[videoIndex])
    }, [videoIndex])

    return (
        <VideosContext.Provider
            value={{
                videoQueue,
                setVideoQueue,
                videoIndex,
                updateVideoIndex,
                secondsElapsedOnCurrentVideo,
                setSecondsElapsedOnCurrentVideo,
                currentVideo,
            }}
        >
            {props.children}
        </VideosContext.Provider>
    )
}

const generateVideoQueue = () => {
    let videoQueue = []
    let videoPool = allVideos

    // ex filtering
    // videoPool = videoPool.filter((video) => {
    //     return video.l < 100
    // })

    while (videoPool.length > 0) {
        let randomVideoIndex = Math.floor(Math.random() * (videoPool.length - 1))
        let video: Video = createVideoFromJson(videoPool[randomVideoIndex])

        videoQueue.push(video)

        videoPool = videoPool.filter((poolVideo) => {
            return poolVideo.u !== video.webpageUrl
        })
    }

    return videoQueue
}

const createVideoFromJson = (videoMetadata: VideoMetadata) => {
    return new Video({ ...videoMetadata })
}

export { VideosProvider, VideosContext }
