const useTimeout = () => {
    let timeout: NodeJS.Timeout = setTimeout(() => {}, 0)

    const startTimer = (callback: () => void, length: number) => {
        timeout = setTimeout(callback, length)
    }

    const resetTimer = () => {
        clearTimeout(timeout)
    }

    return { startTimer, resetTimer }
}

export default useTimeout
