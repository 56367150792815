import React, { useContext, useState } from 'react'
import ReactPlayer from 'react-player'
import { VideosContext } from '../contexts/VideosContext'
import ReactGA from 'react-ga'

interface VideoPlayerProps {
    isPlaying: boolean
    volume: number
}

export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
    const {
        videoIndex,
        updateVideoIndex,
        secondsElapsedOnCurrentVideo,
        setSecondsElapsedOnCurrentVideo,
        currentVideo,
    } = useContext(VideosContext)

    const handleVideoEnded = () => {
        updateVideoIndex(videoIndex + 1)
        ReactGA.event({
            category: 'VIDEO_ENDED',
            action: 'ENDED',
            label: 'VIDEO',
        })
    }

    const [duration, setDuration] = useState<number>(0)

    const onDuration = (duration: number) => {
        setDuration(duration)
    }

    const onProgress = (progress: any) => {
        if (duration === 0) {
            return
        }

        const elapsed = progress.played * duration

        if (elapsed !== secondsElapsedOnCurrentVideo) {
            setSecondsElapsedOnCurrentVideo(Math.round(elapsed))
        }
    }

    return (
        <>
            <div className="playerWrapper max-w-full max-h-screen">
                <ReactPlayer
                    className="reactPlayer"
                    url={'https://www.youtube.com/watch?v=' + currentVideo.webpageUrl}
                    playing={props.isPlaying}
                    controls={false}
                    onEnded={handleVideoEnded}
                    width="100%"
                    height="100%"
                    onProgress={onProgress}
                    onDuration={onDuration}
                    volume={props.volume}
                    config={{
                        youtube: {
                            playerVars: {
                                start: 0,
                                fs: 0,
                                controls: 0,
                                modestbranding: 1,
                                disablekb: 1,
                            },
                        },
                    }}
                />
            </div>
        </>
    )
}
