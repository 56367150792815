import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { VideoPlayerWrapper } from '../components/VideoPlayerWrapper'

interface LandingPageProps {}

export const LandingPage: React.FC<LandingPageProps> = (props) => {
    return (
        <div className="my-auto w-full">
            <VideoPlayerWrapper />
        </div>
    )
}

export default LandingPage
