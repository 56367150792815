import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PlayerOverlayBottom, PlayerOverlayTop } from './PlayerOverlay'
import { VideoPlayer } from './VideoPlayer'
// import FullScreen from 'react-request-fullscreen'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import useTimeout from '../hooks/useTimeout'
import { useHotkeys } from 'react-hotkeys-hook'

interface VideoPlayerWrapperProps {}

export const VideoPlayerWrapper: React.FC<VideoPlayerWrapperProps> = (props) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [volume, setVolume] = useState<number>(25)
    const [isVolumeSliderFocused, setIsVolumeSliderFocused] = useState<boolean>()
    const fullScreenHandle = useFullScreenHandle()

    const toggleIsPlaying = () => {
        setIsPlaying((prev) => !prev)
    }

    const toggleVolumeSliderIsFocused = (type: string) => {
        // This doesn't want to work but should set state
        if (type === 'focus') {
            console.log(setIsVolumeSliderFocused(true))
        } else {
            console.log(setIsVolumeSliderFocused(false))
        }
    }

    const setVolumeUp = () => {
        if (!isVolumeSliderFocused) {
            setVolume((prev) => {
                if (prev + 5 >= 100) {
                    return 100
                }
                return Math.ceil((prev + 5) / 5) * 5
            })
        }
    }

    const setVolumeDown = () => {
        if (!isVolumeSliderFocused) {
            setVolume((prev) => {
                if (prev - 5 <= 0) {
                    return 0
                }

                return Math.ceil((prev - 5) / 5) * 5
            })
        }
    }

    const toggleMute = () => {
        setVolume((prev) => (prev > 0 ? 0 : 25))
    }

    const [isPlayerOverlayVisible, setIsPlayerOverlayVisible] = useState(true)
    const { startTimer, resetTimer } = useTimeout()

    const showOverlay = () => {
        setIsPlayerOverlayVisible(true)

        resetTimer()

        startTimer(() => {
            if (isPlayerOverlayVisible) {
                setIsPlayerOverlayVisible(false)
            }
        }, 1800)
    }

    const handleHotkey = (key: string) => {
        showOverlay()

        switch (key) {
            case 'space':
                toggleIsPlaying()
                break
            case 'up':
                setVolumeUp()
                break
            case 'down':
                setVolumeDown()
                break
            case 'm':
                toggleMute()
                break
            case 'e':
                fullScreenHandle.exit()
                break
            case 'f':
                fullScreenHandle.enter()
                break
            default:
                break
        }
    }

    useEffect(() => {
        return () => {
            resetTimer()
        }
    }, [])

    useHotkeys('space', () => {
        handleHotkey('space')
    })
    useHotkeys('up', (event) => {
        handleHotkey('up')
        event.preventDefault()
    })
    useHotkeys('down', (event) => {
        handleHotkey('down')
        event.preventDefault()
    })
    useHotkeys('m', (event) => {
        handleHotkey('m')
        event.preventDefault()
    })
    useHotkeys('e', () => {
        handleHotkey('e')
    })
    useHotkeys('f', () => {
        handleHotkey('f')
    })

    return (
        <FullScreen handle={fullScreenHandle}>
            <div
                className={`relative bg-stripe-top mx-auto max-w-full ${
                    fullScreenHandle.active ? '' : 'lg:max-w-1280 xl:max-w-1920'
                } ${isPlayerOverlayVisible ? '' : 'cursor-none'}`}
            >
                <div
                    className={`absolute top-0 left-0 right-0 bottom-0 opacity-0 z-10 bg-stripe-bottom ${
                        isPlayerOverlayVisible ? '' : 'cursor-none'
                    }`}
                    onClick={toggleIsPlaying}
                    onMouseMove={showOverlay}
                ></div>
                <PlayerOverlayTop
                    isPlayerOverlayVisible={!isPlaying || isPlayerOverlayVisible}
                    showOverlay={showOverlay}
                    toggleIsPlaying={toggleIsPlaying}
                />
                <VideoPlayer isPlaying={isPlaying} volume={volume / 100} />
                <PlayerOverlayBottom
                    isPlaying={isPlaying}
                    toggleIsPlaying={toggleIsPlaying}
                    isPlayerOverlayVisible={!isPlaying || isPlayerOverlayVisible}
                    fullScreenHandle={fullScreenHandle}
                    volume={volume}
                    setVolume={setVolume}
                    showOverlay={showOverlay}
                    toggleVolumeSliderIsFocused={toggleVolumeSliderIsFocused}
                />
            </div>
        </FullScreen>
    )
}
