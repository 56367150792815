import React, { useEffect, useRef, useState } from 'react'
import { PlayCircleFilled, FiberManualRecordTwoTone, SkipNextTwoTone } from '@material-ui/icons'
import topLogo from '../assets/fhtv_floating.png'
import { Video } from '../interfaces/SharedInterfaces'
import { createMuiTheme, Icon, Slider, Theme, ThemeProvider, Tooltip, withStyles } from '@material-ui/core'
import { VideosContext } from '../contexts/VideosContext'
import moment from 'moment'
import { FullScreenHandle } from 'react-full-screen'

interface PlayerOverlayProps {
    isPlayerOverlayVisible: boolean
    showOverlay: () => void
    toggleIsPlaying: () => void
}

export const PlayerOverlayTop: React.FC<PlayerOverlayProps> = (props) => {
    const { videoQueue, videoIndex, updateVideoIndex, secondsElapsedOnCurrentVideo } = React.useContext(VideosContext)

    // Move these to vid context?
    const [isFifteenSecondsLeft, setIsFifteenSecondsLeft] = useState(false)
    const [isTenSecondsLeft, setIsTenSecondsLeft] = useState(false)
    const [isMiddleOfVideo, setIsMiddleOfVideo] = useState(false)
    const [isStartOfVideo, setIsStartOfVideo] = useState(false)

    useEffect(() => {
        setIsFifteenSecondsLeft(videoQueue[videoIndex].duration - secondsElapsedOnCurrentVideo <= 15)
        setIsTenSecondsLeft(videoQueue[videoIndex].duration - secondsElapsedOnCurrentVideo <= 10)
        setIsMiddleOfVideo(
            secondsElapsedOnCurrentVideo >= videoQueue[videoIndex].duration / 2 - 3 &&
                secondsElapsedOnCurrentVideo <= videoQueue[videoIndex].duration / 2 + 3
        )
        setIsStartOfVideo(secondsElapsedOnCurrentVideo < 4)
    }, [videoIndex, secondsElapsedOnCurrentVideo])

    return (
        <div
            className={`reactPlayerOverlay z-20 text-white ${
                props.isPlayerOverlayVisible || isFifteenSecondsLeft || isMiddleOfVideo || isStartOfVideo
                    ? 'fadeIn'
                    : 'fadeOut'
            }`}
            onMouseMove={props.showOverlay}
        >
            <div className="hidden md:grid grid-cols-3 text-center bg-gray-900 bg-opacity-75">
                <div className="col-span-1">
                    <div className="fancyGradientBig fancyAnimation fancyText text-3xl font-extrabold tracking-wider text-center ">
                        NOW PLAYING
                    </div>
                </div>
                <div className="col-span-1 z-50">
                    <div className="pt-2">
                        <div className="mx-auto">
                            <img
                                className={`mx-auto h-12 md:h-16 pulseImg ${
                                    props.isPlayerOverlayVisible ? 'cursor-default' : 'cursor-none'
                                }`}
                                src={topLogo}
                                alt="FHTV"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="fancyGradientBig fancyAnimation fancyText text-3xl font-extrabold tracking-wider text-center">
                        UP NEXT
                    </div>
                </div>
                <div className="col-span-3">
                    <Banner />
                </div>
            </div>
            <div className="md:-mt-14 lg:-mt-18">
                {!isTenSecondsLeft ? (
                    <div className="hidden md:flex mx-auto">
                        <div className="w-1/3">
                            <VideoCard
                                video={videoQueue[videoIndex]}
                                setVideoIndex={updateVideoIndex}
                                videoIndex={videoIndex}
                                isCurrentVideo={true}
                                secondsElapsed={secondsElapsedOnCurrentVideo}
                                queueDuration={0}
                                isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                            />
                        </div>
                        <div className="w-1/3" onClick={props.toggleIsPlaying}></div>
                        <div className="w-1/3">
                            <VideoCard
                                video={videoQueue[videoIndex + 1]}
                                setVideoIndex={updateVideoIndex}
                                videoIndex={videoIndex + 1}
                                isCurrentVideo={false}
                                secondsElapsed={secondsElapsedOnCurrentVideo}
                                queueDuration={videoQueue[videoIndex].duration}
                                isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="hidden md:flex mx-auto relative">
                        {/* this is a placeholder to get the bg to show */}
                        <div className="w-1/3 opacity-0">
                            <VideoCard
                                video={videoQueue[videoIndex]}
                                setVideoIndex={updateVideoIndex}
                                videoIndex={videoIndex}
                                isCurrentVideo={true}
                                secondsElapsed={secondsElapsedOnCurrentVideo}
                                queueDuration={0}
                                isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                            />
                        </div>
                        <div className="w-1/3 absolute slideLeft0">
                            <VideoCard
                                video={videoQueue[videoIndex]}
                                setVideoIndex={updateVideoIndex}
                                videoIndex={videoIndex}
                                isCurrentVideo={true}
                                secondsElapsed={secondsElapsedOnCurrentVideo}
                                queueDuration={0}
                                isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                            />
                        </div>
                        <div className="w-1/3 absolute slideLeft1">
                            <VideoCard
                                video={videoQueue[videoIndex + 1]}
                                setVideoIndex={updateVideoIndex}
                                videoIndex={videoIndex + 1}
                                isCurrentVideo={true}
                                secondsElapsed={secondsElapsedOnCurrentVideo}
                                queueDuration={0}
                                isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                            />
                        </div>
                        <div className="w-1/3 absolute slideLeft2">
                            <VideoCard
                                video={videoQueue[videoIndex + 2]}
                                setVideoIndex={updateVideoIndex}
                                videoIndex={videoIndex + 2}
                                isCurrentVideo={false}
                                secondsElapsed={secondsElapsedOnCurrentVideo}
                                queueDuration={videoQueue[videoIndex].duration + videoQueue[videoIndex + 1].duration}
                                isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                            />
                        </div>
                    </div>
                )}
                <div className="grid md:hidden w-24/25 mx-auto gap-3 grid-cols-3">
                    <div className="col-span-2">
                        <VideoCard
                            video={videoQueue[videoIndex]}
                            setVideoIndex={updateVideoIndex}
                            videoIndex={videoIndex}
                            isCurrentVideo={true}
                            secondsElapsed={secondsElapsedOnCurrentVideo}
                            queueDuration={0}
                            isPlayerOverlayVisible={props.isPlayerOverlayVisible}
                        />
                    </div>
                    <div
                        className="col-span-1 text-5xl justifySelfCenter self-center leading-none cursor-pointer"
                        onClick={() => updateVideoIndex(videoIndex + 1)}
                    >
                        <SkipNextTwoTone fontSize="inherit" />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface BannerProps {}

const Banner: React.FC<BannerProps> = (props) => {
    return (
        <div className="z-50 relative mt-3 lg:mt-6">
            <div className="fancyGradientBig fancyAnimation min-h-1"></div>
            <div className="hidden md:block fancyGradientBig fancyAnimation mt-tiny min-h-1"></div>
        </div>
    )
}

interface VideoCardProps {
    video: Video
    videoIndex: number
    setVideoIndex: any
    isCurrentVideo: boolean
    secondsElapsed: number
    queueDuration: number
    isPlayerOverlayVisible: boolean
}

const VideoCard: React.FC<VideoCardProps> = (props) => {
    const [playIconIsVisible, setPlayIconIsVisible] = useState(false)

    const secondsUntilVideoPlays = props.queueDuration - props.secondsElapsed

    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000)

    let unixTimestamp = secondsSinceEpoch + secondsUntilVideoPlays
    let date = new Date(unixTimestamp * 1000)
    let displayTime = date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })

    const titleSplits = props.video.title.split(' - ')

    return (
        <div className="leading-tight max-w-lg mx-auto">
            <div
                className={`aspectRatioContainer
                my-1 font-medium text-sm lg:text-lg
                `}
            >
                <div
                    className={`aspectRatioElement z-10 px-2 py-1 flex flex-col bg-gray-900 bg-opacity-50 ${
                        props.isPlayerOverlayVisible
                            ? props.isCurrentVideo
                                ? 'cursor-default'
                                : 'cursor-pointer'
                            : 'cursor-none'
                    }`}
                    onMouseEnter={() => setPlayIconIsVisible(true)}
                    onMouseLeave={() => setPlayIconIsVisible(false)}
                    onClick={() => {
                        if (props.isPlayerOverlayVisible && !props.isCurrentVideo) {
                            props.setVideoIndex(props.videoIndex)
                        }
                    }}
                >
                    <div className="font-bold">
                        {titleSplits.map((split, i) => {
                            return (
                                <div className="truncate" key={i}>
                                    {split}
                                </div>
                            )
                        })}
                    </div>
                    <div className={`m-auto ${playIconIsVisible ? 'fadeIn' : 'fadeOut'}`}>
                        {props.isCurrentVideo ? '' : <PlayCircleFilled fontSize="large" />}
                    </div>
                    <div className="text-left mt-auto">
                        <div className="text-sm mt-1">
                            {props.isCurrentVideo ? (
                                <div className="hidden md:block">
                                    <div>{props.video.playlist}</div>
                                    <div className="flex items-center">
                                        <span>{props.video.viewCount.toLocaleString()} views</span>
                                        <span className="text-xs">
                                            <FiberManualRecordTwoTone className="mx-1" fontSize="inherit" />
                                        </span>
                                        <span>
                                            {moment(
                                                props.video.date.substr(0, 4) +
                                                    '-' +
                                                    props.video.date.substr(4, 2) +
                                                    '-' +
                                                    props.video.date.substr(6, 2)
                                            ).format('MMM D, YYYY')}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                'Starts at ' + displayTime
                            )}
                        </div>
                    </div>
                </div>
                <img
                    className="opacity-75"
                    src={'https://i.ytimg.com/' + props.video.thumbnail}
                    alt={props.video.title}
                />
            </div>
        </div>
    )
}

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: 'rgba(26, 32, 44, 0.75)',
        color: 'white',
        fontSize: '0.75rem',
    },
}))(Tooltip)

interface HtmlTooltipTitleProps {
    firstLine: string
    secondLine: string
}

export const HtmlTooltipTitle: React.FC<HtmlTooltipTitleProps> = (props) => {
    return (
        <div className="text-center">
            <div className="fancyText fancyGradientSmall fancyAnimation font-extrabold tracking-wider">
                {props.firstLine}
            </div>
            <div
                className={`fancyGradientSmall fancyAnimation min-h-px opacity-75 ${
                    props.secondLine.length ? 'block' : 'hidden'
                }`}
            ></div>
            <div>{props.secondLine}</div>
        </div>
    )
}

// interface SettingsSectionProps {}

// const SettingsSection: React.FC<SettingsSectionProps> = (props) => {
//     return <div className="p-5 bg-gray-900">Filter Playlists:</div>
// }

// interface PlaylistElementProps {}

// const PlaylistElement: React.FC<PlaylistElementProps> = (props) => {}

interface PlayerOverlayBottomProps extends PlayerOverlayProps {
    isPlaying: boolean
    volume: number
    setVolume: (e: number) => void
    toggleVolumeSliderIsFocused: (e: string) => void
    fullScreenHandle: FullScreenHandle
}

export const PlayerOverlayBottom: React.FC<PlayerOverlayBottomProps> = (props) => {
    const muiTheme = createMuiTheme({
        overrides: {
            MuiSlider: {
                thumb: {
                    backgroundImage: `linear-gradient(40deg,
                        #f16144 1%,
                        #6c53a3 15%,
                        #f68b1e 30%,
                        #f16144 45%,
                        #6c53a3 60%,
                        #f68b1e 100%)`,
                    animation: 'fancy-animation 6.5s ease-in-out forwards infinite',
                    '&:hover, &:active': {
                        boxShadow: '0 0 1px 1px #f68b1e',
                    },
                },
                track: {
                    backgroundImage: `linear-gradient(40deg,
                        #f16144 1%,
                        #6c53a3 50%,
                        #f68b1e 100%)`,
                    animation: 'fancy-animation 6.5s ease-in-out forwards infinite',
                },
                rail: {
                    backgroundColor: 'white',
                    opacity: 1,
                },
            },
        },
    })

    return (
        <div
            className={`reactPlayerOverlayBottom z-20 bg-gray-900 bg-opacity-75 text-white ${
                props.isPlayerOverlayVisible ? 'fadeIn' : 'fadeOut'
            }`}
            onMouseMove={props.showOverlay}
        >
            <div className="fancyGradientBig fancyAnimation min-h-1"></div>
            <div className="flex items-center justify-items-stretch py-1" id="tooltipContainer">
                {props.isPlaying ? (
                    <HtmlTooltip
                        className={`${props.isPlayerOverlayVisible ? 'block' : 'hidden'}`}
                        title={<HtmlTooltipTitle firstLine="PAUSE" secondLine="(space)" />}
                        placement="top"
                        PopperProps={{ container: document.getElementById('tooltipContainer') }}
                    >
                        <div className="px-3 cursor-pointer leading-none" onClick={props.toggleIsPlaying}>
                            <Icon fontSize="large">pause</Icon>
                        </div>
                    </HtmlTooltip>
                ) : (
                    <HtmlTooltip
                        className={`${props.isPlayerOverlayVisible ? 'block' : 'hidden'}`}
                        title={<HtmlTooltipTitle firstLine="PLAY" secondLine="(space)" />}
                        placement="top"
                        PopperProps={{ container: document.getElementById('tooltipContainer') }}
                    >
                        <div className="px-3 cursor-pointer leading-none" onClick={props.toggleIsPlaying}>
                            <Icon fontSize="large">play_arrow</Icon>
                        </div>
                    </HtmlTooltip>
                )}
                <HtmlTooltip
                    className={`${props.isPlayerOverlayVisible ? 'block' : 'hidden'}`}
                    title={
                        props.volume === 0 ? (
                            <HtmlTooltipTitle firstLine="UNMUTE" secondLine="(m)" />
                        ) : (
                            <HtmlTooltipTitle firstLine="MUTE" secondLine="(m)" />
                        )
                    }
                    placement="top"
                    PopperProps={{ container: document.getElementById('tooltipContainer') }}
                >
                    <div
                        className="ml-3 px-3 cursor-pointer leading-none relative w-10 h-10"
                        onClick={() => {
                            props.setVolume(props.volume > 0 ? 0 : 25)
                        }}
                    >
                        <div className="absolute left-3 z-10">
                            <Icon fontSize="large">volume_mute</Icon>
                        </div>
                        {props.volume <= 0 ? (
                            <></>
                        ) : props.volume < 50 ? (
                            <div className="absolute left-0 fancyText fancyGradientBig fancyAnimation">
                                <Icon fontSize="large">volume_down</Icon>
                            </div>
                        ) : (
                            <div className="absolute left-+3 fancyText fancyGradientBig fancyAnimation">
                                <Icon fontSize="large">volume_up</Icon>
                            </div>
                        )}
                    </div>
                </HtmlTooltip>
                <HtmlTooltip
                    className={`${props.isPlayerOverlayVisible ? 'block' : 'hidden'}`}
                    title={<HtmlTooltipTitle firstLine={props.volume + '%'} secondLine="" />}
                    placement="top"
                    PopperProps={{ container: document.getElementById('tooltipContainer') }}
                >
                    <div className="cursor-pointer leading-none">
                        <div className="w-16 py-1">
                            <ThemeProvider theme={muiTheme}>
                                <Slider
                                    value={props.volume}
                                    min={0}
                                    max={100}
                                    step={5}
                                    onChange={(event, newValue) => {
                                        props.setVolume(newValue as number)
                                    }}
                                    onFocus={() => {
                                        props.toggleVolumeSliderIsFocused('focus')
                                    }}
                                    onBlur={() => {
                                        props.toggleVolumeSliderIsFocused('blur')
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                </HtmlTooltip>
                <div className="px-3 ml-auto cursor-pointer leading-none">
                    {props.fullScreenHandle.active ? (
                        <HtmlTooltip
                            className={`${props.isPlayerOverlayVisible ? 'block' : 'hidden'}`}
                            title={<HtmlTooltipTitle firstLine="FULL SCREEN" secondLine="(e)" />}
                            placement="top"
                            PopperProps={{ container: document.getElementById('tooltipContainer') }}
                        >
                            <div
                                className={`mx-auto ${props.isPlayerOverlayVisible ? 'cursor-pointer' : 'cursor-none'}`}
                                onClick={props.fullScreenHandle.exit}
                            >
                                <Icon fontSize="large">fullscreen_exit</Icon>
                            </div>
                        </HtmlTooltip>
                    ) : (
                        <HtmlTooltip
                            className={`${props.isPlayerOverlayVisible ? 'block' : 'hidden'}`}
                            title={<HtmlTooltipTitle firstLine="FULL SCREEN" secondLine="(f)" />}
                            placement="top"
                            PopperProps={{ container: document.getElementById('tooltipContainer') }}
                        >
                            <div
                                className={`mx-auto ${props.isPlayerOverlayVisible ? 'cursor-pointer' : 'cursor-none'}`}
                                onClick={props.fullScreenHandle.enter}
                            >
                                <Icon fontSize="large">fullscreen</Icon>
                            </div>
                        </HtmlTooltip>
                    )}
                </div>
            </div>
        </div>
    )
}
